import React, { useEffect } from 'react';
import aboutTopImg from './AboutImages/aboutTopImg.png';

const TopBanner = () => {
  useEffect(()=>{

    scrollToTop()
  },[])
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'instant' });
    };
  
  return (
    <div className='mt-4 mb-4 mx-auto max-w-[1260px] rounded-lg shadow-lg gap-4 md:gap-6 md:p-10'>
    <div className="  flex text-lg  flex-col md:flex-row justify-between gap-4 md:gap-6 md:p-10 ">
      <div className="flex-1 md:w-1/2 flex justify-center items-center">
        <img 
          src={aboutTopImg} 
          alt="About us" 
          className="w-full max-w-md rounded-lg object-cover" 
        />
      </div>
      <div className="flex-1 md:w-1/2 flex flex-col justify-center gap-4 p-4">
        {/* <h2 className="text-2xl font-semibold text-red-400">About Us</h2> */}
        {/* <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
          We Sanction Your Dreams and Give You a Happy Disbursal!
        </h1> */}
        <p className="text-gray-700 mb-4">
        <h2 className="text-2xl font-semibold text-red-400">About Us</h2>

        Blubucks Asset Pvt Ltd, we believe that behind every dream lies the spark of possibility, waiting to be nurtured. Life, in its beauty and unpredictability, often presents moments where a helping hand is all we need to bridge the gap between where we are and where we want to be. We are that hand. We are the step that turns your aspirations into reality.
        </p>
        <p className="text-gray-700 mb-4">
        <h2 className="text-2xl font-semibold text-red-400">Our Journey</h2>

        Born from a simple belief—that access to financial support should be within everyone’s reach—we have grown, not by numbers alone, but by the stories of those we’ve helped. Every loan we provide carries the weight of your hopes, your struggles, your ambitions. And it is with care, compassion, and understanding that we’ve built our path, walking alongside you through every milestone, every challenge.

        </p>
        <p className="text-gray-700 text-xl">
        <h2 className="text-2xl font-semibold text-red-400">What We Offer</h2>

        At Blubucks, we don’t just offer loans—we offer possibilities. Whether it’s the key to your first home, the lifeline for a budding business, or a solution to life’s unforeseen turns, we are here, ready to support you. With each financial solution we provide—personal loans, home loans, property loan, business funding—our mission remains the same: to empower you, not just financially, but emotionally.

        </p>
      </div>
 
    </div>
    <div className='p-4'>
      <p className="text-gray-700 text-xl  mb-3">
        <h2 className="text-2xl font-semibold text-red-400">Why We Do What We Do
        </h2>

        <p className="text-gray-700 text-xl"> We know that life doesn’t wait. The unexpected knocks at your door without warning, and sometimes, dreams come at a cost that feels just out of reach. But we believe in more than just numbers and transactions; we believe in the power of trust. We believe in your story. We believe that when given the chance, you will soar.</p>

        <p className="text-gray-700 text-xl">We do what we do because we understand the urgency of opportunity and the comfort of peace of mind. We are here to guide you, to simplify the complicated, to provide clarity when things feel uncertain.</p>


        </p>

        <p className="text-gray-700 text-xl mb-3">
        <h2 className="text-2xl font-semibold text-red-400">Our Promise
        </h2>
        <p className="text-gray-700 text-xl"> We promise to listen before we lend. We promise to walk alongside you, not just as a provider, but as a partner in your journey. Our commitment is not just to meet your needs but to exceed your expectations—offering transparency, flexibility, and unwavering support.</p>
        <p className="text-gray-700 text-xl">
Together, We Build
</p>
<p className="text-gray-700 text-xl">At Blubucks, we are more than a loan company. We are the bridge between dreams deferred and dreams realized. We are the connection between the present and the future you deserve. Our story is built on yours, and together, we will build a future filled with promise and possibility.</p>

<p className="text-gray-700 text-xl">Let us be the wind beneath your wings, lifting you toward the life you envision.</p>

<p className="text-gray-700 text-xl">This version taps into the emotional connection and hope people feel when seeking financial support, presenting your company as more than just a lender but as a compassionate partner.</p>


        </p>
      </div>
    </div>
  );
};

export default TopBanner;
