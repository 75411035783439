import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white pt-10">
      <div className="container mx-auto px-4 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Column 1: Social Media Links */}
          <div className="flex flex-col items-center md:items-start">
            <div className="text-xl font-bold mb-4">Follow Us</div>
            <div className="flex space-x-4">
  <a href="https://wa.me/9717808035" className="text-2xl hover:text-green-500">
    <i className="fab fa-whatsapp"></i>
  </a>
  <a href="https://x.com/Bluebucksloan" className="text-2xl hover:text-red-500">
    <i className="fas fa-times"></i>
  </a>
  <a href="https://www.facebook.com/profile.php?id=615615598849" className="text-2xl hover:text-blue-600">
    <i className="fab fa-facebook"></i>
  </a>
  <a href="https://www.linkedin.com/in/bluebucks-asset-49ba80255/" className="text-2xl hover:text-blue-800">
    <i className="fab fa-linkedin-in"></i>
  </a>
  <a href="https://www.instagram.com/blubucks_loan/" className="text-2xl hover:text-pink-600">
    <i className="fab fa-instagram"></i>
  </a>
  <a href="mailto:support@blubucks.in"className="text-2xl hover:text-gray-400">
    <i className="fas fa-envelope"></i>
  </a>
</div>

          </div>

          {/* Column 2: Most Searched Links */}
          <div className="flex flex-col items-center md:items-start">
            <div className="text-xl font-bold mb-4">Most Searched Links</div>
            <ul className="space-y-2 text-base">
              <li><Link to="/refer-and-earn" className="hover:text-gray-400">Refer & Earn</Link></li>
              <li><Link to="/become-a-partner" className="hover:text-gray-400">Become a Partner</Link></li>
            </ul>
          </div>

          {/* Column 3: Company Info & Products */}
          <div className="flex flex-col items-center md:items-start">
            <div className="text-xl font-bold mb-4">Company & Products</div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-base">
              <ul className="space-y-2">
                <li><Link to="/about-us" className="hover:text-gray-400">About Us</Link></li>
                <li><Link to="/contact-us" className="hover:text-gray-400">Contact Us</Link></li>
                <li><Link to="/privacy-policy" className="hover:text-gray-400">Privacy Policy</Link></li>
              </ul>
              <ul className="space-y-2">
                <li><Link to="/business-against-property" className="hover:text-gray-400">Property Loan</Link></li>
                <li><Link to="/home-loan" className="hover:text-gray-400">Home Loan</Link></li>
                <li><Link to="/personal-loan" className="hover:text-gray-400">Personal Loan</Link></li>
                <li><Link to="/business-loan" className="hover:text-gray-400">Business Loan</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="bg-gray-800 text-center py-4 mt-8">
        <div className="container mx-auto text-sm">
          <p>© 2024 BluBucks — All Rights Reserved</p>
          {/* <p>Toll-free Number </p> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
